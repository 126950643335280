import React, { useState, useEffect } from 'react';
import VisibilitySensor from "react-visibility-sensor"
import COLORS from "../resources/colors"
import CashIcon from "../images/card-purple.png"
import ProfileIcon from "../images/chat-purple.png"
import ContractIcon from "../images/doc-purple.png"
import InfoCell from './InfoCell';

export default class InfoCells extends React.Component {

    constructor(props) {
        super(props)

        this.myRef = React.createRef();

        this.state = {
            active: false,
            height: 0,
            visible: false,
            cell1Visible: false,
            cell2Visible: false,
            cell3Visible: false
        }
    }

    componentDidMount() {

        var height = 0

        if (this.myRef.current != null) {
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height,
            active: true
        })
    }

    visibilityChanged = (visible) => {

        if (visible) {

            this.animateCell1()
            this.setState(
                {
                    visible: visible
                }
            )
        }
    }

    animateCell1() {

        this.setState(
            {
                cell1Visible: true
            }
        )

        setTimeout(() => {
            this.animateCell2()
        }, 400);
    }

    animateCell2() {

        this.setState(
            {
                cell2Visible: true
            }
        )

        setTimeout(() => {
            this.animateCell3()
        }, 400);
    }

    animateCell3() {

        this.setState(
            {
                cell3Visible: true
            }
        )
    }

    render() {

        let backgroundColor = this.props.backgroundColor || COLORS.clear



        return (
            <div ref={this.myRef} id={`${this.props.componentID}-infocells`}>
                <VisibilitySensor key={`${this.props.componentID}-infocells`} active={this.state.active} partialVisibility={true} minTopValue={this.state.height / 2} onChange={this.visibilityChanged}>
                    <div className={`${this.props.componentID}-InfoCellsContent`}>
                        <h1 className={`${this.props.componentID}-InfoCellsTitle`}>{this.props.title || "A simple and powerful solution for all debt"}</h1>
                        <div className={`${this.props.componentID}-InfoCellsCells`}>
                            <div className={this.state.cell1Visible ? "InfoCell1 Visible" : "InfoCell1"}>
                                <InfoCell id={`${this.props.componentID}-infocell1`} imageUrl={ProfileIcon} title={this.props.titleA} paragraph={this.props.paragraphA}></InfoCell>
                            </div>
                            <div className={this.state.cell2Visible ? "InfoCell2 Visible" : "InfoCell2"}>
                                <InfoCell id={`${this.props.componentID}-infocell2`} imageUrl={ContractIcon} title={this.props.titleB} paragraph={this.props.paragraphB}></InfoCell>
                            </div>
                            <div className={this.state.cell3Visible ? "InfoCell3 Visible" : "InfoCell3"}>
                                <InfoCell id={`${this.props.componentID}-infocell3`} imageUrl={CashIcon} title={this.props.titleC} paragraph={this.props.paragraphC}></InfoCell>
                            </div>
                        </div>
                    </div>
                </VisibilitySensor>

                <style jsx="true">{`
                    #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsContent { 
                        padding: 3vw 0px;
                    }
    
                    #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsTitle { 
                        font-weight: 500;
                        font-size: 2.08vw;
                        margin: 0px;
                        color: ${COLORS.white};
                        text-align: center;
                        padding: 0px 0px;
                    }
    
                    #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsCells { 
                        display: grid;
                        grid-template-columns: 1fr 20vw 20vw 20vw 1fr;
                        column-gap: 4vw;
                        align-items: center;
                        padding: 2.98vw 0px 0px 0px;
                    }  

                    @keyframes fadeIn {
                        0% {
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #${this.props.componentID}-infocells .Visible { 
                        animation-duration: 1s;
                        animation-name: fadeIn; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }
    
                    #${this.props.componentID}-infocells .InfoCell1 { 
                        grid-column: 2;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                          opacity: 0;
                    } 
    
                    #${this.props.componentID}-infocells .InfoCell2 { 
                        grid-column: 3;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                        opacity: 0;
                    } 
    
                    #${this.props.componentID}-infocells .InfoCell3 { 
                        grid-column: 4;
                        grid-row: 1;
                        height: 100%;
                        width: 100%;
                        visibility: hidden;
                        opacity: 0;
                    } 
    
                    @media (max-width: 640px) { 
                        #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsContent { 
                            padding: 15vw 0px;
                        }
        
                        #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsTitle { 
                            font-weight: 500;
                            font-size: 5.8vw;
                             
                            margin: auto;
                            color: ${COLORS.myClerkieBlack};
                            text-align: center;
                            padding: 0px 20vw 5vw 20vw;
                        }
        
                        #${this.props.componentID}-infocells .${this.props.componentID}-InfoCellsCells { 
                            display: grid;
                            grid-template-columns: 1fr;
                            column-gap: 0vw;
                            row-gap: 10vw;
                            align-items: center;
                            padding: 5.96vw 0px 0px 0px;
                        }  
        
                        #${this.props.componentID}-infocells .InfoCell1 { 
                            grid-column: 1;
                            grid-row: 1;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #${this.props.componentID}-infocells .InfoCell2 { 
                            grid-column: 1;
                            grid-row: 2;
                            height: 100%;
                            width: 100%;
                        } 
        
                        #${this.props.componentID}-infocells .InfoCell3 { 
                            grid-column: 1;
                            grid-row: 3;
                            height: 100%;
                            width: 100%;
                        } 
                    }
                `}</style>
            </div>
        )
    }


}   